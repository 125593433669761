<template>
    <div class="SettingUpReminder">
        <div class="boxList">
            <div class="titleBox">
                <div class="title">提醒内容</div>
            </div>
        </div>
        <div class="boxList boxList1">
            <van-field style="background-color:#F5F5F5" v-model="message" type="textarea" placeholder="请填写提醒内容" rows="4" autosize />
        </div>
        <div class="boxList">
            <div class="titleBox">
                <div class="title" @click="getTime()">
                    <span>提醒时间</span>
                    <span class="checkSpan">{{reminderDate==''?'选择提醒时间':reminderDate}}</span>
                    <span class="checkSpan2">
                        <i class="iconfont icon-time"></i>
                    </span>
                </div>
            </div>
            <div class="titleBox">
                <div class="title">
                    <span>触发事件</span>
                    <span class="checkSpan"> 始终提醒</span>
                    <span class="checkSpan3">
                        <van-switch v-model="checked" />
                    </span>
                </div>

            </div>
        </div>
        <div class="dialogFooter">
            <van-button class="butSave" size="large" :loading="submitLoading" @click="submitAddFrom(true)">保存</van-button>
        </div>
        <DatePickerTime ref="datePickerTime" />
    </div>
</template>
<script>
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
import titleMixin from '@/mixin/title'
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
export default {
    title: '提醒',
    name: 'SettingUpReminder',
    mixins: [titleMixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            commentFlag: '1',
            submitLoading: false,
            show: true,
            message: '',
            reminderDate: '',
            checked: true,
            mId: ''
        }
    },
    created() {
    },
    mounted() {
        this.pageInit()
    },
    computed: {
    },
    methods: {
        initValue() {
            this.submitLoading = false
            this.checked = true
            this.message = ''
            this.reminderDate = ''
        },
        pageInit() {
            if (this.$route.path == '/mail/SettingUpReminder') {
                this.initValue()
                let { mId } = this.$route.params
                this.mId = mId
            }
        },
        getTime() {
            this.$refs.datePickerTime.getTime((res) => {
                this.reminderDate = res
            })
        },
        submitAddFrom() {
            let _this = this
            let ctId = _this.companie.ctId
            let data = {
                body: {
                    msgBody: _this.message,
                    eventId: ''
                },
                msgSubType: '1',
                moduleCode: 'EM001',
                billId: _this.mId, //
                targets: ctId + '',
                iType: '2',
                deliveryTime: _this.reminderDate + ':00',
                sourceId: 'pc',
                fromAppCode: '',
                formModuleCode: ''
            }
            // data.msgSubType = this.checked == true ? 1 : 6
            _this.submitLoading = true
            _this.axios.post(_this.Global.config.apiBaseURL + _this.Global.api.v2.messenger_post, data).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.initValue()
                    _this.$toast.success(res.data.msg)
                    _this.$router.back()
                } else {
                    _this.submitLoading = false
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.submitLoading = false
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    watch: {
        $route() {
            this.pageInit()
        }
    },
    components: {
        DatePickerTime
        // 'cust-lists': custList
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
